@tailwind base;
@tailwind components;
@tailwind utilities;

.App-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 10px;
  font-size: 0.75rem;
  color: #aaa;
}

@keyframes loading {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loading-bar span {
  display: inline-block;
  opacity: 0;
  animation: loading 3.2s forwards infinite;
}

.loading-bar span:nth-child(1) {
  animation-delay: 0s;
}

.loading-bar span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-bar span:nth-child(3) {
  animation-delay: 0.4s;
}

.loading-bar span:nth-child(4) {
  animation-delay: 0.6s;
}

.loading-bar span:nth-child(5) {
  animation-delay: 0.8s;
}

.loading-bar span:nth-child(6) {
  animation-delay: 1s;
}

.loading-bar span:nth-child(7) {
  animation-delay: 1.2s;
}

.loading-bar span:nth-child(8) {
  animation-delay: 1.4s;
}

.loading-bar span:nth-child(9) {
  animation-delay: 1.6s;
}
